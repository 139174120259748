.bootstrap-iso {
  p,span,pre {
    font-size: 1em !important;
    line-height: 1.2;
  }
  
  clear: both;
}

.social-icons {
    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline;
      }

      a {
        color: var(--link);
      }

      @media (prefers-color-scheme: dark) {
        a {
          color: var(--meta);
        }
      }

      a:hover {
        color: var(--link);
        filter: brightness(0.85);
      }
    }
  }
