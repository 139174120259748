.comments {
  @extend %padding-post;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  float: left;
  width: 100%;
}

article {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
  padding: $padding-x-small $padding-medium;
  clear: both;

  header {
	margin-bottom: 6%;
  }

  a:hover {
	@extend .body-link;
  }

  .footnotes {
	font-size: 0.9em;
  }
}

footer {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9em;
  display: inline-block;
  text-align: center;

  .meta {
	display: inline;
  }

  a.button {
	margin-left: 1em;
  }
}

header {
  h1 {
	margin: 0;
  }
}

.meta {
  color: var(--meta);
  letter-spacing: 0.1em;
  margin: 0;
  text-transform: uppercase;
}

.post-meta {
  font-size: 1rem;
  
  span ~ span {
	padding-left: 1rem;
  }

  .tags {
	ul {
	  display: inline;
	  margin-left: 0;

	  li {
		list-style: none;
		display: inline;
	  }

	  li:after {
		content: " \00b7";
	  }

	  li:last-child:after {
		content: none;
	  }
	}
  }

}

/* --- Post info --- */
.post-info {
  /* For border in author
  border: 1px solid var(--border);
  padding-left: 0.5em;
  */
  border-radius: 1em;
  padding-right: 0.5em;
  display: inline-flex;

  a {
	display: flex;
	align-items: center;

	img {
	  height: auto;
	}
  }

  img {
	border-radius: 1em;
	padding: 0;
	width: 1.5em;
	height: 100%;
	margin-right: 0.5em;
  }
}

/* --- Feature image --- */
.feature-image {
  padding: 0;

  .post-link {
	color: var(--header-text);
  }

  h1.title, .post-info {
	@extend .txt-shadow;
  }

  header {
	color: var(--header-text);
	margin-bottom: 0;
	padding: $padding-large/2.5 $padding-large;

	.meta {
	  color: var(--header-text);
	  filter: opacity(0.7);
	}
  }

  .post-content {
	padding-bottom: 0;
  }

  footer {
	@extend %padding-post;
	padding-top: 0;
  }

  .feature-image-padding {
	padding: $feature-image-padding;
  }
}

/*-- feature-image Responsive scrolling --*/
.feature-image header {
  @media screen and (max-width: $break) {
	padding: $padding-large*1.5 $padding-small;
  }

  @media screen and (min-width: $break) {
	background-size: 120% auto;
  }

  @media screen and (max-width: $break) {
	background-size: 200% auto;
  }

  @media screen and (max-width: $sm-break) {
	background-size: 400% auto;
  }
}

.post-content {
  h1 { color: var(--h1); }
  h2 { color: var(--h2); }
  h3 { color: var(--h3); }
  h4 { color: var(--h4); }
  h5 { color: var(--h5); }
  h6 { color: var(--h6); }

  a {
	:visited {
	  filter: saturate(0.7);
	}
  }

  p {
	text-align: justify;
	hyphens: auto;
  }
}

/* --- Header text feature --- */
.call-out {
  @extend %padding-header;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  text-align: center;
  color: var(--header-text);
  background: no-repeat var(--header-background);
  backdrop-filter: brightness(0.5);
  background-size: cover;

  h1 {
	font-family: $font-family-logo;
	@extend .subtle-txt-shadow;
  }

  /* --- Responsive --- */
  @media screen and (max-width: $m-break) {
	background-size: 170% auto;
  }

  @media screen and (max-width: 450px) {
	background-size: 200% auto;
  }

  @media screen and (max-width: 400px) {
	background-size: 250% auto;
  }

  @media screen and (max-width: 380px) {
	background-size: 280% auto;
  }

  p:last-child {
	margin-bottom: 0;
  }
}

#main {
  background-position: 100% -50px, 0 center, center top;
}

/* --- Post listing --- */
article.posts {
  display: flex;
  justify-content: center;

  ul {
	list-style: none;
	li {
	  margin-bottom: .5rem;

	  .date {
		text-align: right;
		margin-right: 1rem;
	  }
	}
  }
}


.title-padder {
  padding: $title-padding;
}

h1.title {
  padding: 0.5em 0;
}


.subtitle {
  font-weight: normal;
  margin-top: 5px;
}

//Shared css in _post.scss


/* --- Aligner --- */
/* within page/posts */

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0.9% 0 1.5% 0;
}

.column {
  flex: 50%;

  img {
	width: 100%;
	min-width: 250px;
  }

  .single {
	width: 50%;
	display: block;
  }
}

.#{$fa-css-prefix},
.#{$fa-css-prefix}-classic,
.#{$fa-css-prefix}-sharp,
.fas,
.#{$fa-css-prefix}-solid,
.far,
.#{$fa-css-prefix}-regular,
.fab,
.#{$fa-css-prefix}-brands {
  margin-right: .5rem;
}

.center {
  display: flex;
  justify-content: center;
}

.about {
  text-align: center;
}
